import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL } from '@nuxt/ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _00abd727 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _268b8b8c = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _78fd305f = () => interopDefault(import('../pages/afrekenen.vue' /* webpackChunkName: "pages/afrekenen" */))
const _0f3fb25f = () => interopDefault(import('../pages/betaling/index.vue' /* webpackChunkName: "pages/betaling/index" */))
const _46f9b834 = () => interopDefault(import('../pages/brochure-aanvragen.vue' /* webpackChunkName: "pages/brochure-aanvragen" */))
const _d3c7aac6 = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _17f54867 = () => interopDefault(import('../pages/inloggen.vue' /* webpackChunkName: "pages/inloggen" */))
const _48f02bf9 = () => interopDefault(import('../pages/kennisbank/index.vue' /* webpackChunkName: "pages/kennisbank/index" */))
const _5de87c86 = () => interopDefault(import('../pages/nieuws.vue' /* webpackChunkName: "pages/nieuws" */))
const _513d4680 = () => interopDefault(import('../pages/nieuws/index.vue' /* webpackChunkName: "pages/nieuws/index" */))
const _87227464 = () => interopDefault(import('../pages/nieuws/_category/index.vue' /* webpackChunkName: "pages/nieuws/_category/index" */))
const _cde6461e = () => interopDefault(import('../pages/nieuws/_category/_item/index.vue' /* webpackChunkName: "pages/nieuws/_category/_item/index" */))
const _0fe1306c = () => interopDefault(import('../pages/over.vue' /* webpackChunkName: "pages/over" */))
const _3b3264f6 = () => interopDefault(import('../pages/over/_slug.vue' /* webpackChunkName: "pages/over/_slug" */))
const _29dfb671 = () => interopDefault(import('../pages/over-ons.vue' /* webpackChunkName: "pages/over-ons" */))
const _a47d6c56 = () => interopDefault(import('../pages/producten/index.vue' /* webpackChunkName: "pages/producten/index" */))
const _c6984568 = () => interopDefault(import('../pages/registreren.vue' /* webpackChunkName: "pages/registreren" */))
const _5cab45ed = () => interopDefault(import('../pages/school/index.vue' /* webpackChunkName: "pages/school/index" */))
const _781db628 = () => interopDefault(import('../pages/shop.vue' /* webpackChunkName: "pages/shop" */))
const _f8dd6b22 = () => interopDefault(import('../pages/shop/index.vue' /* webpackChunkName: "pages/shop/index" */))
const _75442a06 = () => interopDefault(import('../pages/shop/_category/index.vue' /* webpackChunkName: "pages/shop/_category/index" */))
const _3d8fd8d5 = () => interopDefault(import('../pages/shop/_category/_product.vue' /* webpackChunkName: "pages/shop/_category/_product" */))
const _58c81254 = () => interopDefault(import('../pages/speciaal-voor-scholen.vue' /* webpackChunkName: "pages/speciaal-voor-scholen" */))
const _634d0b09 = () => interopDefault(import('../pages/sso.vue' /* webpackChunkName: "pages/sso" */))
const _909079bc = () => interopDefault(import('../pages/wachtwoord-vergeten/index.vue' /* webpackChunkName: "pages/wachtwoord-vergeten/index" */))
const _98e7a8ec = () => interopDefault(import('../pages/winkelwagen.vue' /* webpackChunkName: "pages/winkelwagen" */))
const _668e940a = () => interopDefault(import('../pages/zoekresultaten.vue' /* webpackChunkName: "pages/zoekresultaten" */))
const _34745077 = () => interopDefault(import('../pages/admin/abonnementen/index.vue' /* webpackChunkName: "pages/admin/abonnementen/index" */))
const _3a5633aa = () => interopDefault(import('../pages/admin/facturen/index.vue' /* webpackChunkName: "pages/admin/facturen/index" */))
const _3ff48602 = () => interopDefault(import('../pages/admin/offertes/index.vue' /* webpackChunkName: "pages/admin/offertes/index" */))
const _6705c4d7 = () => interopDefault(import('../pages/admin/pakketten.vue' /* webpackChunkName: "pages/admin/pakketten" */))
const _43295b44 = () => interopDefault(import('../pages/admin/relaties/index.vue' /* webpackChunkName: "pages/admin/relaties/index" */))
const _6e204279 = () => interopDefault(import('../pages/betaling/afgerond.vue' /* webpackChunkName: "pages/betaling/afgerond" */))
const _41affe7b = () => interopDefault(import('../pages/demo/rekenblobs.vue' /* webpackChunkName: "pages/demo/rekenblobs" */))
const _7c2af968 = () => interopDefault(import('../pages/demo/taalblobs.vue' /* webpackChunkName: "pages/demo/taalblobs" */))
const _5b75195c = () => interopDefault(import('../pages/info/algemene-voorwaarden.vue' /* webpackChunkName: "pages/info/algemene-voorwaarden" */))
const _4102e982 = () => interopDefault(import('../pages/info/privacy-statement.vue' /* webpackChunkName: "pages/info/privacy-statement" */))
const _cbeb49de = () => interopDefault(import('../pages/school/leerling.vue' /* webpackChunkName: "pages/school/leerling" */))
const _2d4962e9 = () => interopDefault(import('../pages/admin/facturen/aanmaken.vue' /* webpackChunkName: "pages/admin/facturen/aanmaken" */))
const _43c12b95 = () => interopDefault(import('../pages/admin/offertes/aanmaken.vue' /* webpackChunkName: "pages/admin/offertes/aanmaken" */))
const _31b02b30 = () => interopDefault(import('../pages/admin/relaties/aanmaken.vue' /* webpackChunkName: "pages/admin/relaties/aanmaken" */))
const _4a0bf6c2 = () => interopDefault(import('../pages/admin/abonnementen/_id.vue' /* webpackChunkName: "pages/admin/abonnementen/_id" */))
const _5f8cf327 = () => interopDefault(import('../pages/admin/offertes/_id.vue' /* webpackChunkName: "pages/admin/offertes/_id" */))
const _6ce9aeec = () => interopDefault(import('../pages/admin/organisaties/_id.vue' /* webpackChunkName: "pages/admin/organisaties/_id" */))
const _44e4a780 = () => interopDefault(import('../pages/admin/particulieren/_id.vue' /* webpackChunkName: "pages/admin/particulieren/_id" */))
const _ebc1757a = () => interopDefault(import('../pages/admin/scholen/_id.vue' /* webpackChunkName: "pages/admin/scholen/_id" */))
const _c77ab8ee = () => interopDefault(import('../pages/admin/studenten/_id.vue' /* webpackChunkName: "pages/admin/studenten/_id" */))
const _c8a600cc = () => interopDefault(import('../pages/wachtwoord-vergeten/reset/_token.vue' /* webpackChunkName: "pages/wachtwoord-vergeten/reset/_token" */))
const _d734ed56 = () => interopDefault(import('../pages/contact/_slug.vue' /* webpackChunkName: "pages/contact/_slug" */))
const _47398ab1 = () => interopDefault(import('../pages/kennisbank/_slug.vue' /* webpackChunkName: "pages/kennisbank/_slug" */))
const _771c0394 = () => interopDefault(import('../pages/kennisbank/_slug/_item.vue' /* webpackChunkName: "pages/kennisbank/_slug/_item" */))
const _a7eaaee6 = () => interopDefault(import('../pages/producten/_slug.vue' /* webpackChunkName: "pages/producten/_slug" */))
const _4d5d263a = () => interopDefault(import('../pages/verleng/_id.vue' /* webpackChunkName: "pages/verleng/_id" */))
const _5aab75f8 = () => interopDefault(import('../pages/wachtwoord-instellen/_token.vue' /* webpackChunkName: "pages/wachtwoord-instellen/_token" */))
const _0a166bcc = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _18ffd824 = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _00abd727,
    name: "account"
  }, {
    path: "/admin",
    component: _268b8b8c,
    name: "admin"
  }, {
    path: "/afrekenen",
    component: _78fd305f,
    name: "afrekenen"
  }, {
    path: "/betaling",
    component: _0f3fb25f,
    name: "betaling"
  }, {
    path: "/brochure-aanvragen",
    component: _46f9b834,
    name: "brochure-aanvragen"
  }, {
    path: "/contact",
    component: _d3c7aac6,
    name: "contact"
  }, {
    path: "/inloggen",
    component: _17f54867,
    name: "inloggen"
  }, {
    path: "/kennisbank",
    component: _48f02bf9,
    name: "kennisbank"
  }, {
    path: "/nieuws",
    component: _5de87c86,
    children: [{
      path: "",
      component: _513d4680,
      name: "nieuws"
    }, {
      path: ":category",
      component: _87227464,
      name: "nieuws-category"
    }, {
      path: ":category/:item",
      component: _cde6461e,
      name: "nieuws-category-item"
    }]
  }, {
    path: "/over",
    component: _0fe1306c,
    name: "over",
    children: [{
      path: ":slug?",
      component: _3b3264f6,
      name: "over-slug"
    }]
  }, {
    path: "/over-ons",
    component: _29dfb671,
    name: "over-ons"
  }, {
    path: "/producten",
    component: _a47d6c56,
    name: "producten"
  }, {
    path: "/registreren",
    component: _c6984568,
    name: "registreren"
  }, {
    path: "/school",
    component: _5cab45ed,
    name: "school"
  }, {
    path: "/shop",
    component: _781db628,
    children: [{
      path: "",
      component: _f8dd6b22,
      name: "shop"
    }, {
      path: ":category",
      component: _75442a06,
      name: "shop-category"
    }, {
      path: ":category/:product",
      component: _3d8fd8d5,
      name: "shop-category-product"
    }]
  }, {
    path: "/speciaal-voor-scholen",
    component: _58c81254,
    name: "speciaal-voor-scholen"
  }, {
    path: "/sso",
    component: _634d0b09,
    name: "sso"
  }, {
    path: "/wachtwoord-vergeten",
    component: _909079bc,
    name: "wachtwoord-vergeten"
  }, {
    path: "/winkelwagen",
    component: _98e7a8ec,
    name: "winkelwagen"
  }, {
    path: "/zoekresultaten",
    component: _668e940a,
    name: "zoekresultaten"
  }, {
    path: "/admin/abonnementen",
    component: _34745077,
    name: "admin-abonnementen"
  }, {
    path: "/admin/facturen",
    component: _3a5633aa,
    name: "admin-facturen"
  }, {
    path: "/admin/offertes",
    component: _3ff48602,
    name: "admin-offertes"
  }, {
    path: "/admin/pakketten",
    component: _6705c4d7,
    name: "admin-pakketten"
  }, {
    path: "/admin/relaties",
    component: _43295b44,
    name: "admin-relaties"
  }, {
    path: "/betaling/afgerond",
    component: _6e204279,
    name: "betaling-afgerond"
  }, {
    path: "/demo/rekenblobs",
    component: _41affe7b,
    name: "demo-rekenblobs"
  }, {
    path: "/demo/taalblobs",
    component: _7c2af968,
    name: "demo-taalblobs"
  }, {
    path: "/info/algemene-voorwaarden",
    component: _5b75195c,
    name: "info-algemene-voorwaarden"
  }, {
    path: "/info/privacy-statement",
    component: _4102e982,
    name: "info-privacy-statement"
  }, {
    path: "/school/leerling",
    component: _cbeb49de,
    name: "school-leerling"
  }, {
    path: "/admin/facturen/aanmaken",
    component: _2d4962e9,
    name: "admin-facturen-aanmaken"
  }, {
    path: "/admin/offertes/aanmaken",
    component: _43c12b95,
    name: "admin-offertes-aanmaken"
  }, {
    path: "/admin/relaties/aanmaken",
    component: _31b02b30,
    name: "admin-relaties-aanmaken"
  }, {
    path: "/admin/abonnementen/:id",
    component: _4a0bf6c2,
    name: "admin-abonnementen-id"
  }, {
    path: "/admin/offertes/:id",
    component: _5f8cf327,
    name: "admin-offertes-id"
  }, {
    path: "/admin/organisaties/:id?",
    component: _6ce9aeec,
    name: "admin-organisaties-id"
  }, {
    path: "/admin/particulieren/:id?",
    component: _44e4a780,
    name: "admin-particulieren-id"
  }, {
    path: "/admin/scholen/:id?",
    component: _ebc1757a,
    name: "admin-scholen-id"
  }, {
    path: "/admin/studenten/:id?",
    component: _c77ab8ee,
    name: "admin-studenten-id"
  }, {
    path: "/wachtwoord-vergeten/reset/:token",
    component: _c8a600cc,
    name: "wachtwoord-vergeten-reset-token"
  }, {
    path: "/contact/:slug",
    component: _d734ed56,
    name: "contact-slug"
  }, {
    path: "/kennisbank/:slug",
    component: _47398ab1,
    name: "kennisbank-slug",
    children: [{
      path: ":item?",
      component: _771c0394,
      name: "kennisbank-slug-item"
    }]
  }, {
    path: "/producten/:slug",
    component: _a7eaaee6,
    name: "producten-slug"
  }, {
    path: "/verleng/:id?",
    component: _4d5d263a,
    name: "verleng-id"
  }, {
    path: "/wachtwoord-instellen/:token?",
    component: _5aab75f8,
    name: "wachtwoord-instellen-token"
  }, {
    path: "/",
    component: _0a166bcc,
    name: "index"
  }, {
    path: "/*",
    component: _18ffd824,
    name: "*"
  }],

  fallback: false
}

function decodeObj(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = decodeURIComponent(obj[key])
    }
  }
}

export function createRouter () {
  const router = new Router(routerOptions)

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    const r = resolve(to, current, append)
    if (r && r.resolved && r.resolved.query) {
      decodeObj(r.resolved.query)
    }
    return r
  }

  return router
}
